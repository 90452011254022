import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import AmazonVsEbayImg from 'images/blog-images/amazon-vs-ebay.png';
import AmazonOrEbayFeatured from 'images/featured-image/amazon-or-ebay-featured.png';
import AmazonOrEbayImg from 'images/blog-images/amazon-or-ebay.png';


interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query SellingOnAmazonOrEbayIMG {
      file(relativePath: { eq: "amazon-or-ebay.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="get prepared for shopping seasons 2020"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="Selling on Amazon or eBay? | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-selling-on-amazon-or-ebay" })}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "Selling on Amazon or eBay?",
              content: intl.formatMessage({ id: "blog-post-meta-title-selling-on-amazon-or-ebay" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: AmazonOrEbayFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="selling-on-amazon-or-ebay"
        title="Selling on Amazon or eBay?"
        date="2020-09-14"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">

            <p>
              Amazon is the cross-border eCommerce retail platform with the largest number of products in the world. It has high regulations for sellers and is suitable for strong sellers. Since it started in 1995, the company has grown to become a market leader in online retail.
            </p>

            <h5>
              <strong>
                Advantage:
              </strong>
            </h5>

            <OrderedList>
              <li>
                There are a huge customer base and total traffic advantage, with total traffic of 80 million per month
              </li>

              <li>
                Strong logistics and warehousing system software and service items
              </li>

              <li>
                Website linkage, such as the Amazon Europe website, only need an account in my country to sell to the European market;
              </li>
            </OrderedList>

            <Image src={AmazonOrEbayImg} className="" alt="amazon or ebay"/>

            <p>
              eBay currently has more than 150 million customers, and it is also the first cross-border eCommerce platform that most sellers in my country have encountered. It is suitable for sellers who have an advantage in supply chain management or have certain export resources.
            </p>

            <h5>
              <strong>
                Advantage:
              </strong>
            </h5>

            <OrderedList>
              <li>
                The actual operation is very simple, and the capital investment is not large;
              </li>

              <li>
                New sellers can be exposed by auction;
              </li>

              <li>
                The threshold for opening a physical store is relatively low, but the standards are complex and require scientific research.
              </li>
            </OrderedList>

            <Image src={AmazonVsEbayImg} className="" alt="amazon vs. ebay"/>

            <p>
              There are some disadvantages of selling on both Amazon and eBay. Therefore, it is best to carefully weigh your options before committing to the platform.
            </p>

            <OrderedList>
              <li>
                Limited control <br />
                Marketplaces do not exist to help you, but to help themselves. They want the focus to be on the products, not the sellers. And that means they might restrict the degree to which you can brand your presence, communicate with customers, dictate what items you can and cannot sell, and so on.
              </li>

              <li>
                Marketplace fees <br />
                Setting up shop on a marketplace can potentially supercharge your sales, but it also exposes you to another cost center: marketplace fees. Most marketplace fees are deducted as a percentage of each sale, and can vary from site to site and even category to category.
              </li>
            </OrderedList>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;